exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("./../../../src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-pay-js": () => import("./../../../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-paymentstatus-js": () => import("./../../../src/pages/paymentstatus.js" /* webpackChunkName: "component---src-pages-paymentstatus-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-portfolio-detail-js": () => import("./../../../src/templates/portfolio-detail.js" /* webpackChunkName: "component---src-templates-portfolio-detail-js" */),
  "component---src-templates-service-detail-js": () => import("./../../../src/templates/service-detail.js" /* webpackChunkName: "component---src-templates-service-detail-js" */)
}

